//Bootstrap carousel
$(function() {
    $(".carousel-inner").each(function() {
        $(this).find(".carousel-item:first").addClass("active");
    });
    $(".carousel-indicators").each(function() {
        $(this).find("li:first").addClass("active");
    });
    $('.carousel').carousel({
        interval: 10000,
        pause: "hover"
    })
});
$(document).ready(function() {      
    $('.carousel.paused').carousel('pause');
});


//Activity panel
$(document).ready(function() {

    //Expand main panel
    $('.activity-panel .toggle-activity-panel').click(function() {
        $('.activity-panel').toggleClass('in');
    });

    //Expand map key
    $('.map-key-header').click(function() {
        $('.map-key').toggleClass('in');
    });    

});


//change map key toggle text
$(".map-key-header").click(function() { if ($(this).text() == "Hide map key") { $(this).text("Show map key"); } else if ($(this).text() == "Show map key") { $(this).text("Hide map key"); } });


//Expanding nav toggle
$('body').on('click', ".toggle-nav", function(){
    $( ".toggle-nav" ).closest( ".main-nav" ).toggleClass( "expanded" );
});

// $('body').on('click', ".subnav-toggle", function(){
//     $(this).closest( ".nav-item" ).toggleClass( "expanded" );
// });



//Toggle grid/list view
$(document).ready(function() {
    $('body').on('click', '.viewmode-toggle .detailed', function() {
        $( ".viewmode-area" ).removeClass( "detailed compact" );
        $( ".viewmode-area" ).addClass( "detailed" );
        $( ".viewmode-toggle li" ).removeClass( "active" );
        $( ".viewmode-toggle li.detailed" ).addClass( "active" );
    });
    $('body').on('click', ".viewmode-toggle .compact" , function() {
        $( ".viewmode-area" ).removeClass( "detailed compact" );
        $( ".viewmode-area" ).addClass( "compact" );
        $( ".viewmode-toggle li" ).removeClass( "active" );
        $( ".viewmode-toggle li.compact" ).addClass( "active" );
    });
});




//Expandable card
$(document).ready(function() {
    $('body').on('click', '.card-expandable .card-expandable-button', function() {
        $(this).closest(".card-expandable").toggleClass('expanded');
        /*
        if ( $( ".card-expandable" ).hasClass( "expanded" ) ) {
            $(".card-expandable").removeClass('expanded');
        }
        */
    });
});



//Options menu
$(document).ready(function() {

    //Expand options menu
    $('body').on('click', '.options-menu .options-menu-toggle', function() {
        $(this).parent().toggleClass('open');
    });

});




//Expandable step blocks
$(document).ready(function() {

    $('body').on('click', '.step-blocks-compact .step-block-header', function() {
        $(this).parent().toggleClass('open');
    });

});